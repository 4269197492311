$font-size: 12pt;

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }
}

body {
  background-color: #eaf5f3;
  font-family: "Proxima Light";
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Proxima Bold";
  src: url("./fonts/ProximaNova-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Proxima Light";
  src: url("./fonts/ProximaNova-Light.otf") format("opentype");
}

@font-face {
  font-family: "Helvetica";
  src: url("./fonts/HelveticaLT-Bold.ttf") format("opentype");
}

.proxima-lt {
  font-family: "Proxima Light";
}

.proxima-bold {
  font-family: "Proxima Bold";
  -webkit-font-smoothing: antialiased;
}

//GENERATOR

//locate
.locate-container {
  background-color: #001c41;
  background: linear-gradient(0deg, #001c41 0%, #002e6d 50%, #001c41 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  .locate-mlb-logo {
    width: 60%;
    margin-left: 20%;
  }
  .locate-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 780px;
    margin: 0 auto;
  }
}

.locate-text {
  color: white;
  font-size: 240%;
  margin-bottom: 0;
  text-align: center;
  margin-bottom: 2vh;
}

.locate-button {
  background-image: url("./images/plate.png");
  background-size: 100%;
  background-position: 50% 23px !important;
  background-repeat: no-repeat;
  font-size: 20pt !important;
  color: white !important;
  width: 160px;
  height: 160px;
}

//capture
.capture-headline {
  font-size: 8vw;
}

.file-form-container {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.edit-image-container {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
}

.file-input-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  input[type="file"] {
    font-size: 8vw !important;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.capture-button {
  font-size: 6vw !important;
  padding: 0 4vw !important;
  margin-top: 2vh !important;
}

@keyframes tilt {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 200%;
  }
}

.tilt-animation {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 300px;
  height: 250px;
  background-image: url("./images/tilt_animation.png");
  // animation: tilt 2s infinite steps(2);
}

//edit
.edit-button {
  width: 50vw;
  font-size: 150% !important;
}

.imagePreview {
  display: none;
  width: 100%;
  height: 300px;
  margin: 0px auto;
  background-size: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}

select,
input,
textarea {
  font-size: 16px !important;
}

#editor {
  display: block;
  margin: 0 auto;
}

.normalize {
  margin: 0;
  padding: 0;
}

//TEAM SELECT
.team-select {
  background-image: url("./images/stadiums/twins.jpg");
  width: 100vw;
  height: 90vh;
  background-size: cover;
  background-position: center;
}

.counter-container {
  text-align: center;
  padding-top: 30px;
}

.counter {
  font-family: "Helvetica";
  color: white;
  font-size: 8vh;
  margin: 0 auto;
}

#teamSelectContainer {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%) translateY(-20%);
  background-color: white;
  border-radius: 10px;
  width: 400px;
  height: 220px;
  padding: 20px;
  color: #727c7e;
  background-color: #eaf5f3;
  font-weight: bold;

  #teamSelect {
    .label {
      font-size: 11pt;
      margin-bottom: 2%;
    }

    .left {
      width: 70%;
      float: left;
    }

    .right {
      input {
        align-self: center;
      }
      float: right;
    }

    select {
      width: 100%;
    }
  }

  #teamSelectSubmitContainer {
    .label {
      margin-top: 2%;
    }

    .yourLocation {
      font-size: 22pt;
      color: black;
      text-rendering: geometricPrecision;
    }

    .inputContainer {
      text-align: center;
      height: 85px;
      width: 100px;
      background-image: url("./images/plate.png");
      background-size: 100%;
      input {
        display: block;
        border-width: -0;
        font-size: 30px !important;
        color: white;
        background-color: rgba(0, 0, 0, 0);
        font-weight: bold;
        margin: 0 auto;
        padding: 0px;
        padding-top: 20px;
        -webkit-appearance: none;
        letter-spacing: 0px;
      }
    }
  }
}

.instruction-modal {
  background-color: #062c3f !important;
  div:nth-of-type(1) {
    // background-color: rgba(0, 0, 0, 0) !important;
  }
  .MuiMobileStepper-dotActive {
    background-color: white !important;
  }
  .MuiMobileStepper-dot {
    background-color: black;
  }
}

.canvas-container {
  margin: 0 auto;
}

#complete-image {
  display: block;
  width: 100%;
}

.hashtag-logo {
  background-image: url("./images/hashtag_logo.png");
}

.mlb-logo {
  background-image: url("./images/mlb_logo.png");
}

.footer {
  position: absolute;
  bottom: 0;
  background-color: white;
  width: 100%;

  .footer_element {
    background-size: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 100%;
  }

  #hashtag_logo {
    width: 300px;
    margin-left: 5%;
    float: left;
  }

  #mlb_logo {
    width: 200px;
    margin-right: 5%;
    float: right;
  }
}

//CONFIRM

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #062c3f;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.edit-image-copy {
  font-size: 90%;
  text-align: center;
}

.confirm-copy {
  font-size: 140%;
  text-align: center;
  font-family: "Proxima Bold";
  color: #062c3f;
}

.fb-logo {
  width: 100%;
  display: block;
}
.twitter-logo {
  width: 100%;
}

#confirm-header {
  width: 100vw;
  height: 50vw;
  text-align: center;
  h1 {
    margin: 0;
    font-size: 6vw;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  #top {
    height: 40%;
  }
  #bottom {
    height: 60%;

    #mlb_logo {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background-position: 50% 50%;
      background-size: 50%;
      background-repeat: no-repeat;
    }
  }
}

.share-container {
  max-width: 250px;
}

//MOBILE OVERRIDES

@media only screen and (max-width: 768px) {
  .footer {
    height: 10vh;
  }

  .team-select {
    height: 84vh;
  }

  .canvas-container {
    width: 100%;
  }

  .capture-headline {
    letter-spacing: -0.2vw;
  }

  .confirm-copy {
    letter-spacing: -0.2vw;
  }

  #teamSelectContainer {
    width: 80%;
    height: 220px;
    padding: 20px;
    color: #727c7e;

    font-weight: bold;

    .yourLocation {
      letter-spacing: -1pt;
      color: #062c3f;
    }

    #teamSelect {
      .left {
        width: 50%;
        float: left;
      }
    }
  }
  .footer {
    #hashtag_logo {
      width: 40%;
      float: left;
    }

    #mlb_logo {
      width: 40%;
    }
  }
}

// // Homepage

.onboarding {
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: relative;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

#onboarding_1 {
  background-image: url("./images/tutorial_1.png");
}

#onboarding_2 {
  background-image: url("./images/tutorial_2.png");
}

#onboarding_3 {
  background-image: url("./images/tutorial_3.png");
}

#onboarding_4 {
  background-image: url("./images/tutorial_4.png");
}

.light {
  font-family: "Proxima Light" !important;
}

.bold {
  font-family: "Proxima Bold" !important;
}

.blue {
  color: #002e6d;
}

#homepage {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 10vh;
}

.header {
  background-color: #002e6d;
  height: 120px;
}

#mlb_logo {
  width: 20vh;
  height: 10vh;
  background-image: url("./images/mlb_logo.png");
  background-size: 80%;
  background-repeat: no-repeat;
  margin: 0 auto;
  background-position: center;
}

.carousel-headline {
  color: #002e6d;
  font-size: 250%;
  margin: 0;
  font-size: calc(2rem + 1vw);
  letter-spacing: -1px;
}

.carousel-paragraph {
  color: white;
  font-family: "Proxima Light";
  font-size: 100%;
}

.carousel-highlight {
  color: white;
  font-family: "Proxima Bold";
  font-size: 100%;
}
.carousel-copy-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 80%;
}

.carousel-inner {
  font-family: "Proxima Bold";
  text-align: center;
  width: 80%;
  height: 70vw;
  max-height: 700px;

  margin: 0 auto;
}

.MuiMobileStepper-root {
  background-color: rgba(0, 0, 0, 0) !important;
  justify-content: center !important;
  padding: 15px !important;
}

.button {
  background-color: #d21f30 !important;
  color: white !important;
}

.donate-button {
  padding: 8px 80px !important;
  font-size: 120% !important;
  // margin-bottom: 35px !important;
}

.donate-text {
  font-size: 80%;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  margin-top: 8px;
}

.play-button,
.tutorial-button {
  padding: 8px 70px !important;
  font-size: 120% !important;
}

.MuiMobileStepper-dotActive {
  background-color: #002e6d !important;
}

.copy-container {
  width: 80%;
  margin: 0 auto;
  font-family: "Proxima Light";
  font-size: 180%;
  text-align: center;
}

.instruction-headline {
  text-align: center;
  font-size: calc(2rem + 1vw);
  letter-spacing: -1px;
}
.instruction-paragraph {
  width: 80%;
  font-size: 120%;
}
.instructions {
  font-size: 120%;
  padding: 0;
  margin: 0;
  text-align: left;
  li {
    margin-top: 10px;
  }
  li:nth-of-type(1) {
    margin-top: 0;
  }
}

.MuiMobileStepper-dotActive {
  background-color: white !important;
}

.disclaimer-inline {
  font-size: 5pt;
  font-family: "Proxima Light";
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.disclaimer-footer {
  width: 100%;
  color: white;
  position: absolute;
  bottom: 0;
  p {
    font-size: 5pt;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 16px;
    font-family: "Proxima Light";
    text-align: center;
  }
}

.close-button {
  font-family: "Proxima Bold";
  font-size: 20pt;
  position: absolute !important;
  top: 10px !important;
  left: 10px !important;
  color: white !important;
}

.black {
  color: black;
}

// ZOOM BG GENERATOR
.bg-generator-canvas {
  display: none;
}

.bg-generator-headline {
  font-family: "Proxima Bold";
  font-size: 20pt;
  color: #0e2f69;
  text-align: center;
}

.home-headline {
  text-align: center;
  font-size: 16pt;
  padding: 0 30px;
}

.bg-confirm-text {
  font-family: "Proxima Bold";
  color: #0e2f69;
  margin: 0;
  font-size: 14pt;
}

.bg-loading-text {
  color: #0e2f69;
  font-family: "Proxima Bold";
}

//ipad font size overrides
@media only screen and (min-width: 768px) {
  .carousel-headline {
    font-size: 450%;
  }
  .carousel-paragraph,
  .instruction-paragraph,
  .carousel-highlight {
    font-size: 180%;
  }
  .footer {
    padding: 1vh 0vh;
  }
}
